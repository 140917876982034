<template>
	<div class="body_functionAdd">
		<div v-loading="loading">
			<div class="child-top">
				<div class="top_title">
					<span></span>
					基本信息
				</div>
				<div class="input-from">
					<div class="operation-button">
						<el-button class="down_btn" @click="$down('/p/test/downTemplate', $route.query.uuid)" round v-if="$route.query.name !== 'look' && $route.query.uuid !== undefined">
							<i class="iconfont icon-xiazai-icon" style="color: #4bb3ff"></i>
							<span>下载模板</span>
						</el-button>
						<el-button class="brotherBtns importFile load room" round style="background: #35c8cb" v-if="$route.query.name !== 'look' && $route.query.uuid !== undefined">
							<div class="fileInp">
								<input @input="$importFile($event, 'enginery')" type="file" />
							</div>
							<i class="iconfont icon-daoru-icon" style="color: #f5a623"></i>
							<span>导入</span>
						</el-button>
						<el-button class="export_btn" round @click="exportSave" v-if="$route.query.name !== 'look'">
							<span>保存</span>
						</el-button>
						<el-button class="export_btn" @click="$expor('/p/test/export', $route.query.uuid)" round style="background: #696ef8" v-if="$route.query.name == 'look'">
							<span>导出</span>
						</el-button>
						<span
							class="fanhui"
							@click="
								() => {
									$router.push({ path: '/conditionModule/functionState', query: { type: 4 } })
								}
							"
						>
							<i class="iconfont iconfanhui"></i>返回
						</span>
					</div>
				</div>
			</div>
			<div class="body_functionAdd_top bottom_btn">
				<el-form v-if="$route.query.name !== 'look'" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo_ruleForm" :label-position="'left'" :inline-message="false">
					<div class="item_flex">
						<el-form-item label="标题" prop="title">
							<el-input v-model="ruleForm.title" class placeholder="请输入标题" :disabled="$route.query.name == 'look'"></el-input>
						</el-form-item>
						<el-form-item label="测试日期" prop="date">
							<el-date-picker
								v-model="ruleForm.date"
								value-format="yyyy-MM-dd"
								type="date"
								:disabled="$route.query.name == 'look'"
								placeholder="请选择日期"
								style="width: 100%"
							></el-date-picker>
						</el-form-item>
					</div>
					<el-form-item label="运动队" prop="department_uuid">
						<el-cascader
							clearable
							style="width: 100%"
							v-model="ruleForm.department_uuid"
							placeholder="请选择所属运动队"
							@change="getSportsMan(true)"
							:show-all-levels="false"
							:options="$store.state.treeMenu"
							:disabled="$route.query.name == 'look'"
							:props="{
								label: 'name',
								value: 'uuid',
								emitPath: false,
							}"
						></el-cascader>
					</el-form-item>
					<el-form-item label="运动员" prop="sportsmanId">
						<el-select v-model="ruleForm.sportsmanId" filterable multiple placeholder="请选择运动员" style="width: 100%" @change="sportsmanChange" :disabled="$route.query.name == 'look'">
							<el-option v-for="item in sportsmanList" :key="item.uuid" :label="item.name" :value="item.uuid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="测试指标" prop="textIndex">
						<!-- <el-input v-model="ruleForm.textIndex" placeholder="请选择测试指标" @focus="selectTextIndex"></el-input> -->
						<div class="text_demo" @click="selectTextIndex" placeholder="请选择测试指标">
							<p v-if="!demoSelectData.length">{{ $route.query.name !== 'look' ? '请选择测试指标' : '' }}</p>
							<span v-else v-for="(item, index) in demoSelectData" :key="index">
								{{ item.name }}
								<i class="el-icon-close" v-show="$route.query.name !== 'look'" @click.stop="demoCancel(item.uuid, index)"></i>
							</span>
						</div>
					</el-form-item>
					<div class="item_flex">
						<el-form-item label="教练" prop="coach">
							<el-input v-model="ruleForm.coach" :placeholder="$route.query.name !== 'look' ? '请输入教练' : ''" :disabled="$route.query.name == 'look'"></el-input>
						</el-form-item>
						<el-form-item label="测试地址" prop="address">
							<el-select
								v-model="ruleForm.address"
								clearable
								:placeholder="$route.query.name !== 'look' ? '请选择测试地址' : ''"
								style="width: 80%"
								:disabled="$route.query.name == 'look'"
							>
								<el-option v-for="item in addressList" :key="item.uuid" :label="item.address" :value="item.uuid">
									<span style="float: left">{{ item.address }}</span>
									<span style="float: right" class="address_del">
										<i class="el-icon-circle-close" @click="address_del(item.uuid)"></i>
									</span>
								</el-option>
							</el-select>
							<el-button type="text" style="margin-left: 8%; color: #0055e9" @click="newAddress" v-show="$route.query.name !== 'look'">新增地址</el-button>
						</el-form-item>
					</div>
					<el-form-item label="备注" prop="demo">
						<el-input
							v-model="ruleForm.demo"
							:placeholder="$route.query.name !== 'look' ? '备注：可填写注意事项、特殊要求、修改内容等说明信息' : ''"
							:disabled="$route.query.name == 'look'"
						></el-input>
					</el-form-item>
				</el-form>
				<el-form v-else :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo_ruleForm" :label-position="'left'" :inline-message="false">
					<div class="item_flex">
						<el-form-item label="标题" prop="title">
							<p class="look_opp">{{ ruleForm.title }}</p>
						</el-form-item>
						<el-form-item label="测试日期" prop="date">
							<p class="look_opp">{{ ruleForm.date }}</p>
						</el-form-item>
					</div>
					<el-form-item label="运动队" prop="department_uuid">
						<p class="look_opp">{{ ruleForm.department_name }}</p>
					</el-form-item>
					<el-form-item label="运动员" prop="sportsmanId">
						<p class="look_opp">{{ ruleForm.staff_name_str }}</p>
					</el-form-item>
					<el-form-item label="测试指标" prop="textIndex">
						<p class="look_opp">
							<span v-for="(value, index) in demoSelectData" :key="index">{{ index !== 0 ? '，' : '' }}{{ value.name }}</span>
						</p>
					</el-form-item>
					<div class="item_flex">
						<el-form-item label="教练" prop="coach">
							<p class="look_opp">{{ ruleForm.coach }}</p>
						</el-form-item>
						<el-form-item label="测试地址" prop="address">
							<p class="look_opp">{{ ruleForm.address_name }}</p>
						</el-form-item>
					</div>
					<el-form-item label="备注" prop="demo">
						<p class="look_opp">{{ ruleForm.demo }}</p>
					</el-form-item>
				</el-form>
			</div>
			<!-- 耐力指标测试表 -->
			<div class="GJ_content" v-if="demoSelectData.length">
				<div class="top_title">
					<span></span>
					机能状态
				</div>
				<el-table :data="GJ_tableData" :header-cell-style="{ background: '#F5F7FA' }" border style="width: 100%" class="GJ_tableData" :span-method="spanMethod">
					<el-table-column label="序号" type="index" width="45" align="center" style="padding: 0">
						<template slot-scope="scope">{{ scope.$index + 1 }}</template>
					</el-table-column>
					<el-table-column prop="staff_name" label="运动员" align="center" style="padding: 0" width="150"></el-table-column>
					<el-table-column prop="sex" label="性别" width="150" style="padding: 0" align="center">
						<template slot-scope="scope">{{ scope.row.sex == '1' ? '男' : '女' }}</template>
					</el-table-column>
					<el-table-column align="center" v-for="(itm, idx) in GJObject" :key="idx" :label="`${itm.norm_name}${itm.norm_unit ? '(' + itm.norm_unit + ')' : ''}`">
						<template slot-scope="scope">
							<div
								v-if="$route.query.name == 'look'"
								:class="scope.row.staff_name !== '男子-团队平均值' && scope.row.staff_name !== '女子-团队平均值' ? `ename${scope.$index}${idx} lookColor` : ''"
							>
								{{ scope.row.norms[idx].norm_value }}
							</div>
							<el-input
								v-else
								:class="`ename${scope.$index}${idx}`"
								v-model="scope.row.norms[idx].norm_value"
								@input="ruleValueChange(itm.norm_name, scope.$index, idx)"
								@blur="ALLMean(scope.row.sex, scope.$index, idx, itm.norm_name)"
								:disabled="scope.row.staff_name == '男子-团队平均值' || scope.row.staff_name == '女子-团队平均值' || $route.query.name == 'look'"
							></el-input>
						</template>
					</el-table-column>
				</el-table>
				<p class="notes">注：<span></span>为指标值偏低 <span></span>为指标值偏高</p>
			</div>
			<div class="GJ_echarts" v-show="demoSelectData.length">
				<div class="top_title">
					<span></span>
					机能状态测试图
				</div>
				<div class="JR_echarts">
					<div class="e_left_sele">
						<el-select v-model="cateSelect" placeholder="请选择指标" @change="sexChange()">
							<el-option v-for="(item, index) in demoSelectData" :key="index" :label="item.name" :value="item.name"></el-option>
						</el-select>
						<el-select v-model="cateSex" placeholder="请选择性别" clearable @change="sexChange()">
							<el-option key="1" label="男" value="1" v-if="GJoldman == '1' || GJoldman == '3'"></el-option>
							<el-option key="2" label="女" value="2" v-if="GJoldman == '2' || GJoldman == '3'"></el-option>
						</el-select>
					</div>
					<div class="wl_e_line" id="eLine"></div>
				</div>
			</div>
			<!-- 测试评价 -->
			<div class="test_content">
				<div class="top_title">
					<span></span>
					测试评价
				</div>
				<div class="test_editor" id="editor" ref="editorElem" style="width: 100%"></div>
			</div>
		</div>

		<!-- 新建地址 -->
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogAddress" :before-close="address_cancel" title="新增地址" width="55%" center>
			<div class="addressForm_dialog">
				<el-form :model="addressForm" :rules="addressRules" ref="addressForm" label-width="90px" class="demo_ruleForm addressForm_dialog" :label-position="'left'" :inline-message="false">
					<div class="item_flex">
						<el-form-item label="国家" prop="country">
							<el-select v-model="addressForm.country" placeholder="请选择国家" style="width: 100%" @change="countryChange('country')">
								<el-option v-for="item in countryList" :key="item.id" :label="item.label" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="省市" prop="city">
							<el-select v-model="addressForm.city" placeholder="请选择省市" style="width: 100%" @change="countryChange('city')">
								<el-option v-for="item in cityList" :key="item.id" :label="item.label" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="item_flex">
						<el-form-item label="市区" prop="region">
							<el-select v-model="addressForm.region" placeholder="请选择市区" style="width: 100%">
								<el-option v-for="item in regionList" :key="item.id" :label="item.label" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="输入详细地址" prop="address">
							<el-input v-model="addressForm.address" placeholder="请输入详细地址"></el-input>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="form-bottom-button">
				<el-button @click="address_save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
				<el-button @click="address_cancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>
		<!-- 测试指标 -->
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogTextIndex" :before-close="text_cancel" title="选择指标" width="50%" center>
			<div class="ModifyDoalog">
				<div class="textDialog_left">
					<div class="textDialog_left_input">
						<el-input
							@keyup.enter.native="selectTextIndex"
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入指标名称"
							suffix-icon="iconfont iconsousuo"
							v-model="inputValue"
						></el-input>
					</div>
					<h2>指标列表</h2>
					<div class="tree_demo">
						<el-tree
							empty-text="暂无数据"
							:data="textTreeData"
							ref="textTreeNode"
							show-checkbox
							node-key="uuid"
							:props="defaultProps"
							:check-strictly="false"
							:check-on-click-node="false"
							:filter-node-method="filterNode"
							@check="checkChange"
						></el-tree>
					</div>
				</div>
				<div class="textDialog_right">
					<p>
						已选指标 ( {{ selectNum }}/{{ textAllNum }} )
						<span @click="clickTreeAll">清空</span>
					</p>
					<div class="tree_demo">
						<el-tree :data="selectTreeData" node-key="uuid" :props="defaultProps">
							<span class="custom-tree-node" slot-scope="{ node, data }">
								<span>{{ node.label }}</span>
								<span>
									<i class="iconfont iconshanchu-moren" @click="textTreeCancel(node, data)" v-if="data.showCheckbox"></i>
								</span>
							</span>
						</el-tree>
					</div>
				</div>
			</div>
			<p class="bz_content">*注：一个指标同属于多个分类，指标只呈现在一个分类中</p>
			<div class="form-bottom-button">
				<el-button @click="text_save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
				<el-button @click="text_cancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import E from 'wangeditor'
export default {
	data() {
		return {
			editor: null,
			editorContent: '',
			loading: false,
			newAddressName: '',
			textIndex: [],
			addressList: [],
			sportsmanList: [],
			countryList: [],
			cityList: [],
			regionList: [],
			textTreeData: [],
			oldTreeData: [],
			selectTreeData: [],
			saveSelectTreeData: [],
			demoSelectData: [],
			oldAddress: '',
			inputValue: '',
			textAllNum: 0,
			selectNum: 0,
			selectTextName: [],

			GJObject: [],
			GJ_selectName: [],
			GJ_tableData: [],

			dialogAddress: false,
			dialogTextIndex: false,

			ruleForm: {
				title: '',
				data: null,
				department_uuid: '',
				sportsmanId: [],
				address: '',
			},

			addressForm: {
				country: '',
				city: '',
				region: '',
				address: '',
			},

			defaultProps: {
				children: 'norms',
				label: 'name',
			},
			rules: {
				title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
				date: { required: true, message: '请选择测试日期', trigger: 'blur' },
				department_uuid: { required: true, message: '请选择运动队', trigger: 'change' },
				sportsmanId: { required: true, message: '请选择运动员', trigger: 'change' },
			},
			addressRules: {
				country: { required: true, message: '请选择国家', trigger: 'blur' },
			},
			GJ_sex: '',
			GJoldman: 0,
			GJ_object: {
				cate_uuid: '',
				cate_name: '',
				staffs: null,
			},
			cateNameData: [],
			cateSelectOptions: [],
			cateSex: '',
			cateSelect: '',
		}
	},
	watch: {
		inputValue(val) {
			this.$refs.textTreeNode.filter(val)
		},
	},
	computed: {},
	mounted() {
		this.getAddressData()
		this.editor = new E(this.$refs.editorElem)
		// 编辑器的事件，每次改变会获取其html内容
		this.editor.config.onchange = html => {
			this.editorContent = html
		}
		this.editor.config.zIndex = 1000
		// this.editor.config.uploadImgServer = this.$store.state.img_url + '/p/annex/add'
		// this.editor.config.uploadFileName = 'file'
		this.editor.config.uploadImgShowBase64 = true
		this.editor.config.menus = [
			// 菜单配置
			'head', // 标题
			'bold', // 粗体
			'fontSize', // 字号
			'fontName', // 字体
			'italic', // 斜体
			'underline', // 下划线
			'strikeThrough', // 删除线
			'foreColor', // 文字颜色
			'backColor', // 背景颜色
			'link', // 插入链接
			'list', // 列表
			'justify', // 对齐方式
			'quote', // 引用
			'image', // 插入图片
			'table', // 表格
			'code', // 插入代码
		]
		this.editor.create() // 创建富文本实例
		if (this.$route.query.name == 'look') {
			this.editor.disable()
		}

		this.selectTextIndex('编辑')

		if (this.$route.query.uuid) {
			this.loading = true
			this.$axios
				.post(
					'/p/test/read',
					this.$qs({
						uuid: this.$route.query.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.ruleForm = {
							title: res.data.data.title,
							date: res.data.data.test_date,
							department_uuid: res.data.data.department_uuid,
							department_name: res.data.data.department_name,
							coach: res.data.data.coach,
							address: res.data.data.address_uuid,
							address_name: res.data.data.address,
							demo: res.data.data.demo,
							staff_uuid_str: res.data.data.staff_uuid_str,
							staff_name_str: res.data.data.staff_name_str,
						}
						if (this.ruleForm.address_name !== '' || this.ruleForm.address !== '') {
							this.addressList = this.$dataBack(this.addressList, { address: this.ruleForm.address_name, uuid: this.ruleForm.address }, 'uuid', 'address')
						}
						this.editorContent = res.data.data.summary
						this.editor.txt.html(res.data.data.summary)
						this.getSportsMan()
						this.$set(this.ruleForm, 'sportsmanId', this.ruleForm.staff_uuid_str.split(','))
						this.ruleForm = Object.assign({}, this.ruleForm)
						this.saveSelectTreeData = res.data.data.cate_norm
						this.selectTreeData = res.data.data.cate_norm
						this.saveSelectTreeData.forEach(item => {
							if (item.norms.length) {
								item.norms.forEach(itm => {
									this.demoSelectData.push(itm)
								})
							} else {
								this.demoSelectData.push(item)
							}
							this.selectTextName.push(item.name)
						})
						this.cateSelect = this.demoSelectData[0].name
						var that = this
						res.data.data.detail.forEach(item => {
							let obj = {
								sex: item.sex,
								staff_name: item.staff_name,
								staff_uuid: item.staff_uuid,
								total_value: '',
								norms: [],
							}
							for (var i in item.norms) {
								obj.norms.push({
									json_value: [],
									norm_uuid: item.norms[i].norm_uuid,
									norm_value: item.norms[i].norm_value,
									norm_name: i,
									value_type: 0,
								})
							}
							this.GJ_tableData.push(JSON.parse(JSON.stringify(obj)))
						})
						if (this.GJ_tableData.length) {
							this.GJ_tableData[0].norms.forEach(item => {
								this.GJObject.push({
									norm_uuid: item.norm_uuid,
									norm_name: item.norm_name,
									norm_value: '',
									json_value: [],
									value_type: 0,
								})
							})
							this.GJObject.forEach((item, index) => {
								this.oldTreeData.forEach((itm, idx) => {
									itm.norms.forEach((im, ix) => {
										if (item.norm_name == im.name) {
											item.norm_unit = im.unit_name
										}
									})
								})
							})
						}
						//  this.getSlectName()
						setTimeout(() => {
							this.sportsmanChange()
						}, 100)
						setTimeout(() => {
							this.loading = false
						}, 800)
					} else {
						this.loading = false
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		}
	},
	methods: {
		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},
		// 获取运动员列表
		getSportsMan(type) {
			this.sportsmanList = []
			this.ruleForm.sportsmanId = []
			if (!this.ruleForm.department_uuid) {
				return false
			}
			let obj = {
				department_uuid: this.ruleForm.department_uuid,
				type: 'staff',
			}
			if (this.$route.query.uuid) {
				obj.uuid = this.$route.query.uuid
				obj.area = 'test'
			}
			this.$axios
				.post('p/Staff/getStaffByStation', this.$qs(obj))
				.then(res => {
					if (res.data.code == 0) {
						this.sportsmanList = res.data.data
						this.sportsmanList.unshift({
							name: '全选',
							uuid: '0',
						})
						if (type) {
							this.GJ_tableData = []
						}
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 获取地址列表
		getAddressData(name) {
			this.$axios
				.get('/p/address/all')
				.then(res => {
					if (res.data.code == 0) {
						this.addressList = res.data.data
						if (name == 'new') {
							this.ruleForm.address = this.addressList[0].uuid
						}
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 获取城市列表
		getCountryData() {
			this.$axios
				.get('/p/city/all')
				.then(res => {
					if (res.data.code == 0) {
						this.countryList = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		countryChange(name) {
			if (name == 'country') {
				this.countryList.map(item => {
					this.addressForm.city = ''
					this.addressForm.region = ''
					this.regionList = []
					if (item.id == this.addressForm.country) {
						this.cityList = item.children
					}
				})
			} else if (name == 'city') {
				this.addressForm.region = ''
				this.cityList.map(item => {
					if (item.id == this.addressForm.city) {
						this.regionList = item.children
					}
				})
			}
		},
		// 新建地址
		newAddress() {
			this.getCountryData()
			this.dialogAddress = true
		},
		address_save() {
			this.$refs['addressForm'].validate(valid => {
				if (valid) {
					this.$axios
						.post(
							'/p/address/add',
							this.$qs({
								country_id: this.addressForm.country,
								province_id: this.addressForm.city,
								city_id: this.addressForm.region,
								address: this.addressForm.address,
							})
						)
						.then(res => {
							if (res.data.code == 0) {
								this.$message({
									type: 'success',
									message: res.data.message,
								})
								this.address_cancel()
								this.getAddressData('new')
							} else {
								this.$message({
									type: 'error',
									message: res.data.message,
								})
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					return false
				}
			})
		},
		address_cancel() {
			this.dialogAddress = false
			this.$refs.addressForm.resetFields()
		},
		address_del(uuid) {
			setTimeout(() => {
				this.$set(this.ruleForm, 'address', '')
			}, 0)
			this.$axios
				.post(
					'/p/address/del',
					this.$qs({
						uuid: uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.getAddressData()
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 选择测试指标
		selectTextIndex(name) {
			// if(this.$route.query.name == 'look') {
			//   return false
			// }
			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))

			let obj = {
				type: '7',
				keyword: this.inputValue,
			}
			if (name !== '编辑') {
				this.dialogTextIndex = true
			}
			if (this.$route.query.uuid) {
				obj.test_uuid = this.$route.query.uuid
			}
			this.$axios
				.post(
					'/p/cate/structNormCount',
					this.$qs({
						type: '7',
					})
				)
				.then(res => {
					this.textAllNum = res.data.data
				})
			this.$axios
				.post('/p/cate/structNorm', this.$qs(obj))
				.then(res => {
					if (res.data.code == 0) {
						res.data.data.forEach(item => {
							if (item.builtin == 1) {
								item.norms.forEach(itm => {
									itm.builtin = 1
									itm.showCheckbox = false
								})
								item.builtin = 0
								item.showCheckbox = true
							} else {
								item.showCheckbox = true
								item.norms.forEach(itm => {
									itm.showCheckbox = true
								})
							}
						})
						this.oldTreeData = JSON.parse(JSON.stringify(res.data.data))
						this.textTreeData = JSON.parse(JSON.stringify(res.data.data))
						this.selectNum = 0
						this.selectTreeData.forEach(item => {
							if (item.norms.length) {
								this.selectNum += item.norms.length
							} else {
								this.selectNum += 1
							}
						})
						if (name !== '编辑') {
							this.$nextTick(() => {
								this.selectTreeData.forEach(item => {
									this.$refs.textTreeNode.setChecked(item.uuid, true)
									item.norms.forEach(itm => {
										this.$refs.textTreeNode.setChecked(itm.uuid, true)
									})
								})
							})
						}
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},

		text_save() {
			this.newDelData = JSON.parse(JSON.stringify(this.GJ_tableData))
			this.dialogTextIndex = false
			this.demoSelectData = []
			this.cateSelectOptions = []
			this.saveSelectTreeData = JSON.parse(JSON.stringify(this.selectTreeData))
			this.GJObject = []
			var newData = []
			if (this.saveSelectTreeData.length) {
				this.saveSelectTreeData.forEach(item => {
					if (item.norms.length) {
						item.norms.forEach(itm => {
							this.demoSelectData.push(itm)
						})
					} else {
						this.demoSelectData.push(item)
					}
					item.norms.forEach(itm => {
						this.GJObject.push({
							norm_name: itm.name,
							norm_uuid: itm.uuid,
							norm_unit: itm.unit_name,
							norm_value: '',
							json_value: [],
							value_type: 0,
						})
					})
				})
			} else {
				this.GJ_tableData = []
			}
			if (this.GJ_tableData.length) {
				this.GJObject.forEach((item, index) => {
					this.GJ_tableData.forEach((itm, idx) => {
						let type = true
						itm.norms.forEach((im, ix) => {
							if (item.norm_name == im.norm_name) {
								type = false
							}
							// if(!type) {
							//   item.norms.forEach((i, x) => {
							//     let c_type = true
							//     im.norms.forEach((o, m) => {
							//       if(i.norm_name == o.norm_name) {
							//         c_type = false
							//       }
							//     })
							//     if(c_type) {
							//       im.norms.push(JSON.parse(JSON.stringify(i)))
							//     }
							//   })
							// }
						})
						if (type) {
							itm.norms.push(JSON.parse(JSON.stringify(item)))
						}
					})
				})
				newData = JSON.parse(JSON.stringify(this.GJ_tableData))

				this.GJ_tableData[0].norms.forEach((item, index) => {
					let type = true,
						cname = item.norm_name
					// if (item.norms.length) {
					// item.norms.forEach((im, ix) => {
					let c_type = true,
						i = -1,
						c_name = item.norm_name

					this.GJObject.forEach(itm => {
						if (itm.norm_name == item.norm_name) {
							c_type = false
						}
					})

					if (c_type) {
						newData.forEach((cm, cx) => {
							cm.norms.forEach((am, ax) => {
								if (am.norm_name == c_name) {
									cm.norms.splice(ax, 1)
								}
							})
						})
					}
					// })
					// }
				})
				this.GJ_tableData = JSON.parse(JSON.stringify(newData))

				var sortData = []
				this.GJ_tableData[0].norms.forEach((itm, idx) => {
					this.GJObject.forEach((item, index) => {
						if (item.norm_name == itm.norm_name) {
							sortData[idx] = item
						}
					})
				})
				this.GJObject = sortData
			}
			this.cateSelect = this.demoSelectData.length ? this.demoSelectData[0].name : ''
			this.sexChange()
			this.sportsmanChange()
		},
		demoCancel(uid, index) {
			this.demoSelectData.splice(index, 1)
			this.GJObject.forEach((item, ix) => {
				if (item.norm_uuid == uid) {
					this.GJObject.splice(ix, 1)
				}
			})
			this.saveSelectTreeData.forEach((item, idx) => {
				if (item.uuid == uid) {
					this.saveSelectTreeData.splice(idx, 1)
				} else if (item.norms.length) {
					item.norms.forEach((im, ix) => {
						if (im.uuid == uid) {
							item.norms.splice(ix, 1)
						}
					})
					if (!item.norms.length) {
						this.saveSelectTreeData.splice(idx, 1)
					}
				}
			})
			if (this.saveSelectTreeData == []) {
				this.GJObject = []
			}

			var newData = JSON.parse(JSON.stringify(this.GJ_tableData))

			this.GJ_tableData[0].norms.forEach((item, index) => {
				let type = true,
					cname = item.norm_name
				let c_type = true,
					i = -1,
					c_name = item.norm_name
				this.GJObject.forEach(itm => {
					if (itm.norm_name == item.norm_name) {
						c_type = false
					}
				})
				if (c_type) {
					newData.forEach((cm, cx) => {
						cm.norms.forEach((am, ax) => {
							if (am.norm_name == c_name) {
								cm.norms.splice(ax, 1)
							}
						})
					})
				}
			})
			this.GJ_tableData = JSON.parse(JSON.stringify(newData))

			var sortData = []
			this.GJ_tableData[0].norms.forEach((itm, idx) => {
				this.GJObject.forEach((item, index) => {
					if (item.norm_name == itm.norm_name) {
						sortData[idx] = item
					}
				})
			})
			this.GJObject = sortData
			// this.getSlectName()

			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
		},
		text_cancel() {
			this.dialogTextIndex = false
			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
		},
		clickTreeAll() {
			this.selectTreeData.forEach((item, index) => {
				this.$refs.textTreeNode.setChecked(item.uuid, false)
				item.norms.forEach(itm => {
					this.$refs.textTreeNode.setChecked(itm.uuid, false)
				})
			})
			this.selectTreeData = []
			this.selectNum = 0
		},
		textTreeCancel(node, data) {
			this.$refs.textTreeNode.setChecked(data.uuid, false)
			this.selectTreeData.forEach((item, index) => {
				if (item.uuid == data.uuid) {
					item.norms.forEach(itm => {
						this.$refs.textTreeNode.setChecked(itm.uuid, false)
					})
					this.selectTreeData.splice(index, 1)
				} else if (item.norms !== []) {
					item.norms.forEach((itm, idx) => {
						if (itm.uuid == data.uuid) {
							item.norms.splice(idx, 1)
							if (item.norms.length == 0) {
								this.selectTreeData.splice(index, 1)
							}
						}
					})
				}
			})
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
		},
		checkChange(node, data) {
			let is_fit = true

			var selectData = JSON.parse(JSON.stringify(this.selectTreeData))
			selectData.forEach((item, index) => {
				if (item.uuid == node.uuid) {
					is_fit = false
					if (item.norms.length == node.norms.length) {
						selectData.splice(index, 1)
					} else {
						item.norms = node.norms
					}
				} else if (item.norms !== []) {
					item.norms.forEach((itm, idx) => {
						if (itm.uuid == node.uuid) {
							is_fit = false
							selectData[index].norms.splice(idx, 1)
							if (item.norms.length == 0) {
								selectData.splice(index, 1)
							}
						}
					})
				}
			})
			this.selectTreeData = selectData
			if (is_fit) {
				var treeData = JSON.parse(JSON.stringify(this.textTreeData))
				treeData.forEach((im, ix) => {
					var ims = JSON.parse(JSON.stringify(im))
					if (ims.uuid == node.uuid) {
						this.selectTreeData.push(ims)
					} else if (ims.norms !== []) {
						ims.norms.forEach((i, x) => {
							if (i.uuid == node.uuid) {
								let type = true
								this.selectTreeData.forEach((o, m) => {
									if (o.uuid == ims.uuid) {
										type = false
										o.norms.push(i)
									}
								})
								if (type) {
									ims.norms = []
									ims.norms.push(i)
									this.selectTreeData.push(ims)
								}
							}
						})
					}
				})
			}
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
		},
		exportSave() {
			let t_data = JSON.parse(JSON.stringify(this.GJ_tableData))
			if (t_data.length) {
				if (this.GJoldman !== 3) {
					t_data.pop()
				} else {
					t_data.pop()
					t_data.pop()
				}
			}
			let url = '/p/test/add'
			let data = {
				title: this.ruleForm.title,
				test_date: this.ruleForm.date,
				department_uuid: this.ruleForm.department_uuid,
				staff_uuid_str: this.ruleForm.sportsmanId.toString(','),
				coach: this.ruleForm.coach,
				address_uuid: this.ruleForm.address,
				type: '7',
				demo: this.ruleForm.demo,
				cate_norm: JSON.stringify(this.selectTreeData),
				detail: JSON.stringify(t_data),
				summary: this.editorContent,
			}
			if (this.$route.query.uuid) {
				url = '/p/test/update'
				data.uuid = this.$route.query.uuid
			}
			this.$axios
				.post(url, this.$qs(data))
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.$router.push({ path: '/conditionModule/functionState', query: { type: 4 } })
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		spanMethod({ row, column, rowIndex, columnIndex }) {
			if (row.staff_name == '男子-团队平均值' || row.staff_name == '女子-团队平均值') {
				if (columnIndex === 0) {
					return [0, 0]
				} else if (columnIndex === 1) {
					return [1, 3]
				} else if (columnIndex === 2) {
					return [0, 0]
				}
			}
		},
		ruleValueChange(name, s_index, index) {
			let type = ''
			this.oldTreeData.forEach(item => {
				item.norms.forEach(itm => {
					if (itm.name == name) {
						type = itm.value_type
						return false
					}
				})
			})
			if (type == '1') {
				let value = this.GJ_tableData[s_index].norms[index].norm_value
				var regExp = /^[0-9][0-9]*[.]?([0-9]+)?$/
				if (!regExp.test(value)) {
					this.GJ_tableData[s_index].norms[index].norm_value = ''
				}
			}
		},
		sportsmanChange() {
			let index = this.ruleForm.sportsmanId.indexOf('0')
			if (index !== -1) {
				let data = []
				this.sportsmanList.forEach(itm => {
					data.push(itm.uuid)
				})
				this.ruleForm.sportsmanId = data
				this.ruleForm.sportsmanId.splice(0, 1)
			}

			if (!this.selectTreeData.length) {
				return false
			}
			if (this.GJoldman == 1 || this.GJoldman == 2) {
				this.GJ_tableData.pop()
			} else if (this.GJoldman == 3) {
				this.GJ_tableData.pop()
				this.GJ_tableData.pop()
			}
			this.ruleForm.sportsmanId.forEach(item => {
				this.sportsmanList.forEach(itm => {
					if (item == itm.uuid) {
						let type = true
						this.GJ_tableData.forEach((im, ix) => {
							if (im.staff_uuid == item) {
								type = false
							}
						})
						if (type) {
							this.GJ_tableData.push({
								staff_uuid: itm.uuid,
								staff_name: itm.name,
								sex: itm.sex,
								norms: JSON.parse(JSON.stringify(this.GJObject)),
							})
						}
					}
				})
			})
			this.GJ_tableData.forEach((item, index) => {
				let o_type = true
				this.ruleForm.sportsmanId.forEach(itm => {
					if (item.staff_uuid == itm) {
						o_type = false
					}
				})
				if (o_type) {
					this.GJ_tableData.splice(index, 1)
				}
			})
			let man = 0
			this.GJ_tableData.forEach((item, index) => {
				if (!man) {
					man = item.sex
				}
				if (item.sex !== man) {
					man = 3
				}
			})
			if (man == 1) {
				this.GJ_tableData.push({
					staff_uuid: '',
					staff_name: '男子-团队平均值',
					sex: 1,
					norms: JSON.parse(JSON.stringify(this.GJObject)),
				})
			} else if (man == 2) {
				this.GJ_tableData.push({
					staff_uuid: '',
					staff_name: '女子-团队平均值',
					sex: 2,
					norms: JSON.parse(JSON.stringify(this.GJObject)),
				})
			} else if (man == 3) {
				this.GJ_tableData.push({
					staff_uuid: '',
					staff_name: '男子-团队平均值',
					sex: 1,
					norms: JSON.parse(JSON.stringify(this.GJObject)),
				})
				this.GJ_tableData.push({
					staff_uuid: '',
					staff_name: '女子-团队平均值',
					sex: 2,
					norms: JSON.parse(JSON.stringify(this.GJObject)),
				})
			}
			this.GJoldman = man
			setTimeout(() => {
				this.ALLMean()
			}, 100)
		},
		getSlectName() {},
		ALLMean(sex, s_index, idx, name) {
			let manIndex = -1,
				womanIndex = -1
			let max = 0,
				min = 0

			if (!s_index && s_index != 0) {
				this.GJ_tableData.forEach((item, i) => {
					if (item.staff_name == '男子-团队平均值') {
						manIndex = i
					} else if (item.staff_name == '女子-团队平均值') {
						womanIndex = i
					}
				})
				this.GJObject.forEach((item, index) => {
					this.getSum(item.norm_name, manIndex, womanIndex, index)
				})
			} else {
				let value = this.GJ_tableData[s_index].norms[idx].norm_value
				this.getValue(name, sex, value, s_index, idx)
				var manNormNum = 0,
					womanNormNum = 0,
					manNormSum = 0,
					womanNormSum = 0
				this.GJ_tableData.forEach((item, i) => {
					if (item.staff_name == '男子-团队平均值') {
						manIndex = i
					} else if (item.staff_name == '女子-团队平均值') {
						womanIndex = i
					} else if (item.sex == 1) {
						if (item.norms[idx].norm_value !== '') {
							manNormNum++
							manNormSum += Number(item.norms[idx].norm_value)
						}
					} else if (item.sex == 2) {
						if (item.norms[idx].norm_value !== '') {
							womanNormNum++
							womanNormSum += Number(item.norms[idx].norm_value)
						}
					}
				})
				if (manNormNum !== 0) {
					this.GJ_tableData[manIndex].norms[idx].norm_value = (manNormSum / manNormNum).toFixed(1)
				} else if (manIndex !== -1) {
					this.GJ_tableData[manIndex].norms[index].norm_value = ''
				}
				if (womanNormNum !== 0) {
					this.GJ_tableData[womanIndex].norms[idx].norm_value = (womanNormSum / womanNormNum).toFixed(1)
				} else if (womanIndex !== -1) {
					this.GJ_tableData[womanIndex].norms[index].norm_value = ''
				}
			}
			// if(!index && index != 0) {
			//   this.$nextTick(() => {
			//     this.cateNameData.forEach((a, b) => {
			//       this.YBTLine(a, b)
			//     })
			//   })
			// }
			this.$nextTick(() => {
				this.YBTLine()
			})
		},

		getValue(name, sex, value, s_index, idx) {
			let wordColor = 0 //0：未填或标准 ，1：不足，2：超标
			if (value !== '' && value !== null) {
				this.oldTreeData.forEach(item => {
					item.norms.forEach(itm => {
						if (itm.name == name) {
							if (sex == 2 && itm.value_type == 1 && (itm.female_lower_limit || itm.female_upper_limit)) {
								switch (itm.female_symbol) {
									case 1:
										if (value < itm.female_upper_limit) {
											wordColor = 0
										} else {
											wordColor = 2
										}
										break
									case 2:
										if (value <= itm.female_upper_limit) {
											wordColor = 0
										} else {
											wordColor = 2
										}
										break
									case 3:
										if (value > itm.female_lower_limit) {
											wordColor = 0
										} else {
											wordColor = 1
										}
										break
									case 4:
										if (value >= itm.female_lower_limit) {
											wordColor = 0
										} else {
											wordColor = 1
										}
										break
									// 1:<,2:<=,3:>,4:>=,5:(),6:(],7:[),8:[]
									case 5:
										if (value > itm.female_lower_limit && value < itm.female_upper_limit) {
											wordColor = 0
										} else if (value <= itm.female_lower_limit) {
											wordColor = 1
										} else if (value >= itm.female_upper_limit) {
											wordColor = 2
										}
										break
									case 6:
										if (value > itm.female_lower_limit && value <= itm.female_upper_limit) {
											wordColor = 0
										} else if (value <= itm.female_lower_limit) {
											wordColor = 1
										} else if (value > itm.female_upper_limit) {
											wordColor = 2
										}
										break
									case 7:
										if (value >= itm.female_lower_limit && value < itm.female_upper_limit) {
											wordColor = 0
										} else if (value < itm.female_lower_limit) {
											wordColor = 1
										} else if (value >= itm.female_upper_limit) {
											wordColor = 2
										}
										break
									case 8:
										if (value >= itm.female_lower_limit && value <= itm.female_upper_limit) {
											wordColor = 0
										} else if (value < itm.female_lower_limit) {
											wordColor = 1
										} else if (value > itm.female_upper_limit) {
											wordColor = 2
										}
										break
									default:
										break
								}
							} else if (sex == 1 && itm.value_type == 1 && (itm.male_lower_limit || itm.male_upper_limit)) {
								switch (itm.male_symbol) {
									case 1:
										if (value < itm.male_upper_limit) {
											wordColor = 0
										} else {
											wordColor = 2
										}
										break
									case 2:
										if (value <= itm.male_upper_limit) {
											wordColor = 0
										} else {
											wordColor = 2
										}
										break
									case 3:
										if (value > itm.male_lower_limit) {
											wordColor = 0
										} else {
											wordColor = 1
										}
										break
									case 4:
										if (value >= itm.male_lower_limit) {
											wordColor = 0
										} else {
											wordColor = 1
										}
										break
									// 1:<,2:<=,3:>,4:>=,5:(),6:(],7:[),8:[]
									case 5:
										if (value > itm.male_lower_limit && value < itm.male_upper_limit) {
											wordColor = 0
										} else if (value <= itm.male_lower_limit) {
											wordColor = 1
										} else if (value >= itm.male_upper_limit) {
											wordColor = 2
										}
										break
									case 6:
										if (value > itm.male_lower_limit && value <= itm.male_upper_limit) {
											wordColor = 0
										} else if (value <= itm.male_lower_limit) {
											wordColor = 1
										} else if (value > itm.male_upper_limit) {
											wordColor = 2
										}
										break
									case 7:
										if (value >= itm.male_lower_limit && value < itm.male_upper_limit) {
											wordColor = 0
										} else if (value < itm.male_lower_limit) {
											wordColor = 1
										} else if (value >= itm.male_upper_limit) {
											wordColor = 2
										}
										break
									case 8:
										if (value >= itm.male_lower_limit && value <= itm.male_upper_limit) {
											wordColor = 0
										} else if (value < itm.male_lower_limit) {
											wordColor = 1
										} else if (value > itm.male_upper_limit) {
											wordColor = 2
										}
										break
									default:
										break
								}
							}
							return false
						}
					})
				})
			}
			let ename = 'ename' + s_index + '' + idx
			// document.getElementsByClassName(ename)[0].parentNode.parentNode
			if (this.$route.query.name == 'look') {
				document.getElementsByClassName(ename)[0].parentNode.style.padding = 0
				document.getElementsByClassName(ename)[0].parentNode.parentNode.style.padding = 0
				if (wordColor == 1) {
					document.getElementsByClassName(ename)[0].style.background = 'rgba(38, 154, 46, 1)'
					document.getElementsByClassName(ename)[0].style.color = '#fff'
				} else if (wordColor == 2) {
					document.getElementsByClassName(ename)[0].style.background = 'rgba(255, 129, 129, 1)'
					document.getElementsByClassName(ename)[0].style.color = '#fff'
				}
			} else {
				if (wordColor == 0) {
					document.getElementsByClassName(ename)[0].classList.remove('lowEname')
					document.getElementsByClassName(ename)[0].classList.remove('upEname')
				} else if (wordColor == 1) {
					document.getElementsByClassName(ename)[0].classList.remove('lowEname')
					document.getElementsByClassName(ename)[0].classList.add('upEname')
				} else if (wordColor == 2) {
					document.getElementsByClassName(ename)[0].classList.remove('upEname')
					document.getElementsByClassName(ename)[0].classList.add('lowEname')
				}
			}
		},

		getSum(n_name, manIndex, womanIndex, index) {
			let type = this.getNormType(n_name)
			var manNormNum = 0,
				womanNormNum = 0,
				manNormSum = 0,
				womanNormSum = 0
			this.GJ_tableData.forEach((item, a) => {
				item.norms.forEach((im, b) => {
					if (im.norm_name == n_name) {
						if (item.sex == '1' && im.norm_value !== '' && a !== manIndex && a !== womanIndex) {
							this.getValue(im.norm_name, 1, im.norm_value, a, b)
							manNormNum++
							manNormSum += Number(im.norm_value)
						} else if (item.sex == '2' && im.norm_value !== '' && a !== manIndex && a !== womanIndex) {
							this.getValue(im.norm_name, 2, im.norm_value, a, b)
							womanNormNum++
							womanNormSum += Number(im.norm_value)
						}
					}
				})
				if (manNormNum !== 0) {
					// this.GJ_tableData[manIndex].norms[index].norm_value = (manNormSum / manNormNum).toFixed(1)
					let a = (manNormSum / manNormNum).toFixed(1)
					this.$set(this.GJ_tableData[manIndex].norms[index], 'norm_value', a)
				} else if (manIndex !== -1) {
					this.GJ_tableData[manIndex].norms[index].norm_value = ''
				}
				if (womanNormNum !== 0) {
					this.GJ_tableData[womanIndex].norms[index].norm_value = (womanNormSum / womanNormNum).toFixed(1)
				} else if (womanIndex !== -1) {
					this.GJ_tableData[womanIndex].norms[index].norm_value = ''
				}
			})
		},
		getNormType(name) {
			let type = ''
			if (this.oldTreeData.length) {
				this.oldTreeData.forEach((item, index) => {
					item.norms.forEach((itm, idx) => {
						if (itm.name == name) {
							return (type = itm.type)
						}
					})
				})
			} else if (this.demoSelectData.length) {
				this.demoSelectData.forEach((item, index) => {
					if (item.name == name) {
						return (type = item.value_type)
					}
				})
			}
			return type
		},

		getTime(f_value, m_value) {
			let num = 0,
				second = 0
			if (f_value !== '') {
				num += Number(f_value) * 60
			}
			if (m_value !== '') {
				num += Number(m_value)
			}
			second = (num / 60).toFixed(1)
			return [num, second]
		},
		getTimeSum(a_sum, a_num) {
			let s_sum = (a_sum / a_num).toFixed(1)
			let s_num = parseInt(s_sum / 60)
			if (s_sum >= 60) {
				let s_redidue = s_sum - s_num * 60
				return [s_num, s_redidue]
			} else {
				return ['', s_sum]
			}
		},
		sexChange() {
			this.$forceUpdate()
			this.$echarts.init(document.getElementById('eLine')).dispose()
			this.$nextTick(() => {
				this.YBTLine()
			})
		},
		selectSort(arr) {
			if (Array.isArray(arr)) {
				for (var i = arr.length - 1; i > 0; i--) {
					for (var j = 0; j < i; j++) {
						if (Number(arr[j].value) < Number(arr[j + 1].value)) {
							;[arr[j], arr[j + 1]] = [arr[j + 1], arr[j]]
						}
					}
				}
				return arr
			}
		},

		// 有问题 找海军 ！！！！！
		// -------- Echarts ------------------
		YBTLine() {
			let edata = JSON.parse(JSON.stringify(this.GJ_tableData))

			if (this.GJoldman !== 3) {
				edata.pop()
			} else {
				edata.pop()
				edata.pop()
			}

			if (this.cateSex) {
				let data = []
				edata.forEach(item => {
					if (item.sex == this.cateSex) {
						data.push(item)
					}
				})
				edata = data
			}
			let manData = [],
				manNum = 0,
				manSum = 0
			let e_data = []
			edata.forEach(item => {
				item.norms.forEach(im => {
					if (im.norm_name == this.cateSelect) {
						manData.push({
							sex: item.sex,
							value: im.norm_value,
							staff_name: item.staff_name,
						})
					}
				})
			})
			manData = this.selectSort(manData)
			let female_lower_limit = 0,
				female_upper_limit = 0,
				male_lower_limit = 0,
				male_upper_limit = 0,
				fetype = 0,
				etype = 0 // 0：男子上下限 1：上限 2： 下限
			let unit = ''
			this.oldTreeData.forEach(item => {
				item.norms.forEach(itm => {
					if (itm.name == this.cateSelect) {
						// female_lower_limit = itm.female_lower_limit
						// female_upper_limit = itm.female_upper_limit
						// male_lower_limit = itm.male_lower_limit
						// male_upper_limit = itm.male_upper_limit
						if (itm.female_lower_limit && itm.female_upper_limit) {
							female_lower_limit = itm.female_lower_limit
							female_upper_limit = itm.female_upper_limit
						} else if (itm.female_lower_limit) {
							female_lower_limit = itm.female_lower_limit
							fetype = 1
						} else if (itm.female_upper_limit) {
							female_upper_limit = itm.female_upper_limit
							fetype = 2
						}
						if (itm.male_lower_limit && itm.male_upper_limit) {
							male_lower_limit = itm.male_lower_limit
							male_upper_limit = itm.male_upper_limit
						} else if (itm.male_lower_limit) {
							male_lower_limit = itm.male_lower_limit
							etype = 1
						} else if (itm.male_upper_limit) {
							male_upper_limit = itm.male_upper_limit
							etype = 2
						}
						if (itm.value_type == 3) {
							unit = '分'
						} else {
							unit = itm.unit_name
						}
					}
				})
			})
			let serum = this.$echarts.init(document.getElementById('eLine'))
			let optionS = {
				title: {
					x: 'center',
					text: this.cateSelect,
					textStyle: {
						//标题内容的样式
						color: '#333333', //京东红
						fontStyle: 'normal', //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: this.GJoldman == 3 && this.cateSex == '' ? '{b}<br />' + this.cateSelect + ': {c}{c1}' : '{b}<br />' + this.cateSelect + ': {c}',
				},
				legend: {
					data: ['男', '女'],
					// right: 40,
					top: 50,
					left: 'center',
					icon: 'rect', //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					itemGap: 20, // 设置间距
					itemStyle: {},
				},
				grid: {
					left: '5%',
					right: '0%',
					bottom: '3%',
					top: '29%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: manData.map(item => {
						return item.staff_name
					}),
					axisLabel: {
						color: '#333',
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: unit ? '单位: ' + unit : '单位: ',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 25, 0],
					},
				},
				color: ['#269A2E', '#FF8181'],
			}
			let series = []
			if (!etype) {
				series.push({
					name: '男',
					type: 'bar',
					stack: '总量',
					itemStyle: {
						normal: {
							color: '#1790FF',
						},
					},
					barWidth: '16',
					markLine: {
						silent: true, //true 去掉鼠标悬浮该线上的动画
						symbol: 'none', //该线无样式
						label: {
							width: male_lower_limit && male_upper_limit ? '1' : 0,
							show: true, //该线上的值去掉,
							position: 'insideMiddleTop',
							formatter: '{b}: {c}',
						},
						lineStyle: {
							//设置该线样式
							normal: {
								width: male_lower_limit && male_upper_limit ? '1' : '0',
								type: 'dashed',
								color: '#2FC25B',
							},
						},
						data: [
							{
								// 起点和终点的项会共用一个 name
								name: '男子下限',
								type: 'min',
								yAxis: male_lower_limit,
							},
							{
								name: '男子上限',
								type: 'max',
								yAxis: male_upper_limit,
							},
						],
					},
					data: manData.map(item => {
						if (item.sex == '1') {
							return item.value
						}
					}),
				})
			} else if (etype == 1) {
				series.push({
					name: '男',
					type: 'bar',
					stack: '总量',
					itemStyle: {
						normal: {
							color: '#1790FF',
						},
					},
					barWidth: '16',
					markLine: {
						silent: true, //true 去掉鼠标悬浮该线上的动画
						symbol: 'none', //该线无样式
						label: {
							width: male_lower_limit ? '1' : 0,
							show: male_lower_limit ? true : false, //该线上的值去掉,
							position: 'insideMiddleTop',
							formatter: '{b}: {c}',
						},
						lineStyle: {
							//设置该线样式
							normal: {
								width: male_lower_limit ? '1' : 0,
								type: 'dashed',
								color: '#2FC25B',
							},
						},
						data: [
							{
								// 起点和终点的项会共用一个 name
								name: '男子下限',
								type: 'min',
								yAxis: male_lower_limit,
							},
						],
					},
					data: manData.map(item => {
						if (item.sex == '1') {
							return item.value
						}
					}),
				})
			} else if (etype == 2) {
				series.push({
					name: '男',
					type: 'bar',
					stack: '总量',
					itemStyle: {
						normal: {
							color: '#1790FF',
						},
					},
					barWidth: '16',
					markLine: {
						silent: true, //true 去掉鼠标悬浮该线上的动画
						symbol: 'none', //该线无样式
						label: {
							width: '1',
							show: true, //该线上的值去掉,
							position: 'insideMiddleTop',
							formatter: '{b}: {c}',
						},
						lineStyle: {
							//设置该线样式
							normal: {
								width: '1',
								type: 'dashed',
								color: '#2FC25B',
							},
						},
						data: [
							{
								// 起点和终点的项会共用一个 name
								name: '男子上限',
								type: 'min',
								yAxis: male_upper_limit,
							},
						],
					},
					data: manData.map(item => {
						if (item.sex == '1') {
							return item.value
						}
					}),
				})
			}

			if (!fetype) {
				series.push({
					name: '女',
					type: 'bar',
					stack: '总量',
					itemStyle: {
						normal: {
							color: '#F79645',
						},
					},
					barWidth: '16',
					markLine: {
						silent: true, //true 去掉鼠标悬浮该线上的动画
						symbol: 'none', //该线无样式
						label: {
							width: '1',
							show: true, //该线上的值去掉,
							position: 'insideEndTop',
							formatter: '{b}: {c}',
						},
						lineStyle: {
							//设置该线样式
							normal: {
								width: '1',
								type: 'dashed',
								color: '#FF8181',
							},
						},
						data: [
							{
								// 起点和终点的项会共用一个 name
								name: '女子下限',
								type: 'min',
								yAxis: female_lower_limit,
							},
							{
								name: '女子上限',
								type: 'max',
								yAxis: female_upper_limit,
							},
						],
					},
					data: manData.map(item => {
						if (item.sex == '2') {
							return item.value
						}
					}),
				})
			} else if (fetype == 1) {
				series.push({
					name: '女',
					type: 'bar',
					stack: '总量',
					itemStyle: {
						normal: {
							color: '#F79645',
						},
					},
					barWidth: '16',
					markLine: {
						silent: true, //true 去掉鼠标悬浮该线上的动画
						symbol: 'none', //该线无样式
						label: {
							width: '1',
							show: true, //该线上的值去掉,
							position: 'insideEndTop',
							formatter: '{b}: {c}',
						},
						lineStyle: {
							//设置该线样式
							normal: {
								width: '1',
								type: 'dashed',
								color: '#FF8181',
							},
						},
						data: [
							{
								// 起点和终点的项会共用一个 name
								name: '女子下限',
								type: 'min',
								yAxis: female_lower_limit,
							},
						],
					},
					data: manData.map(item => {
						if (item.sex == '2') {
							return item.value
						}
					}),
				})
			} else if (fetype == 2) {
				series.push({
					name: '女',
					type: 'bar',
					stack: '总量',
					itemStyle: {
						normal: {
							color: '#F79645',
							height: '10px',
						},
					},
					barWidth: '16',
					markLine: {
						silent: true, //true 去掉鼠标悬浮该线上的动画
						symbol: 'none', //该线无样式
						label: {
							width: '1',
							show: true, //该线上的值去掉,
							position: 'insideEndTop',
							formatter: '{b}: {c}',
						},
						lineStyle: {
							//设置该线样式
							normal: {
								width: '1',
								type: 'dashed',
								color: '#FF8181',
							},
						},
						data: [
							{
								// 起点和终点的项会共用一个 name
								name: '女子上限',
								type: 'min',
								yAxis: female_upper_limit,
							},
						],
					},
					data: manData.map(item => {
						if (item.sex == '2') {
							return item.value
						}
					}),
				})
			}
			optionS.series = series
			if (this.cateSex == 1) {
				optionS.series.forEach((item, index) => {
					if (item.name == '女') {
						optionS.series.splice(index, 1)
					}
				})
			} else if (this.cateSex == 2) {
				optionS.series.forEach((item, index) => {
					if (item.name == '男') {
						optionS.series.splice(index, 1)
					}
				})
			}
			serum.setOption(optionS)
			this.$echartsResize(serum)
		},
	},
}
</script>

<style lang="scss" scoped>
.body_functionAdd_top {
	background: #fff;
	height: auto;
	padding: 10px 100px 30px 60px;
	border-radius: 4px;
}
// ------------------
// 关节角度
.GJ_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.GJ_tableData {
		margin-top: 20px;
		.lowEname {
			/deep/ .el-input__inner {
				background: rgba(255, 129, 129, 1);
				color: #fff;
			}
		}
		.upEname {
			/deep/ .el-input__inner {
				background: rgba(38, 154, 46, 1);
				color: #fff;
			}
		}
	}
}
.GJ_echarts {
	background: #fff;
	height: auto;
	padding: 0 100px 0 50px;
	margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
		padding-top: 40px;
	}
	.JR_echarts {
		height: 400px;
		position: relative;
		margin-top: 40px;
		.wl_e_line {
			width: 100%;
			height: 100%;
		}
		.e_left_sele {
			position: absolute;
			top: 45px;
			right: 0%;
			z-index: 9999;
			.el-select:first-child {
				width: 120px;
				margin-right: 20px;
			}
			.el-select:last-child {
				width: 120px;
			}
		}
		&:last-child {
			padding-bottom: 30px;
		}
	}
}
// ------------------
// 测试评价
.test_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.test_editor {
		width: 100%;
		margin-top: 40px;
		height: 400px;
	}
	.look_editor {
		overflow: auto;
		border: 1px solid #f5f7fa;
		border-radius: 5px;
		padding: 20px;
	}
}
.child-top {
	align-items: flex-end;
	margin-bottom: 0;
}
.operation-button {
	.fanhui {
		font-size: 16px;
		color: #666;
		display: inline-block;
		padding-top: 5px;
		margin-left: 40px;
		margin-right: 20px;
		i {
			margin-right: 5px;
			color: #000;
		}
		&:hover {
			cursor: pointer;
		}
	}
}
.top_title {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #333;
	font-weight: 500;
	font-family: PingFangSC-Medium, PingFang SC;
	margin-left: 30px;
	span {
		display: inline-block;
		width: 6px;
		height: 20px;
		background: #0055e9;
		border-radius: 3px;
		margin-right: 10px;
	}
}
.demo_ruleForm {
	.item_flex {
		display: flex;
		justify-content: space-between;
		.el-form-item {
			width: 45%;
		}
	}
	.look_opp {
		border-bottom: 2px solid #cccccc;
		color: #666;
		padding-left: 10px;
		height: 40px;
		line-height: 40px;
	}
}
.addressForm_dialog {
	padding: 0 20px 50px;
}
.form-bottom-button {
	padding-bottom: 30px;
}
/deep/ .bottom_btn {
	.el-input .el-input__inner {
		border-radius: 0;
		border-right-width: 0;
		border: none;
		border-bottom: 2px solid #ccc;
	}

	.el-input .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select {
		float: left;
	}

	.el-select .el-input__inner {
		border-right-width: 0;
		border: none;
		border-radius: 0;
		border-bottom: 2px solid #ccc;
	}

	.el-select .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select .el-input__inner:focus {
		border-right-width: 1px;
	}

	.bot_content {
		p {
			display: flex;
			align-items: center;
			width: 100%;

			i {
				font-size: 22px;
			}

			span {
				font-size: 18px;
			}
		}
	}
}
.text_demo {
	width: 99.4%;
	min-height: 35px;
	border-bottom: 2px solid #ccc;
	padding-left: 5px;
	span {
		padding: 5px 3px 5px 10px;
		border-radius: 5px;
		background-color: #f4f4f5;
		border-color: #e9e9eb;
		color: #909399;
		font-size: 14px;
		margin-right: 7px;
		margin-bottom: 3px;
		i {
			border-radius: 50%;
			background-color: #c0c4cc;
			font-size: 14px;
			color: #909399;
			margin-left: 5px;
			&:hover {
				color: #fff;
				cursor: pointer;
			}
		}
	}
	p {
		padding-left: 10px;
		color: #c2c2c2;
		font-size: 14px;
	}
}
.address_del {
	&:hover {
		color: #f00;
		cursor: pointer;
	}
}
.ModifyDoalog {
	display: flex;
	height: 400px;
	padding: 0 30px;
	justify-content: space-between;
	margin-bottom: 10px;
	.textDialog_left {
		width: 45%;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		.textDialog_left_input {
			padding: 10px 20px;
		}
		h2 {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border: 1px solid #e3e3e3;
			border-left: none;
			border-right: none;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			margin-top: 5px;
		}
		.tree_demo {
			height: 280px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}
			}
		}
	}
	.textDialog_right {
		width: 45%;
		border: 1px solid #e3e3e3;
		p {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border-bottom: 1px solid #e3e3e3;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			position: relative;
			span {
				position: absolute;
				right: 20px;
				font-size: 14px;
				font-family: MicrosoftYaHei;
				color: #999999;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.tree_demo {
			height: 345px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}

				.custom-tree-node {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					padding-right: 8px;
					i {
						&:hover {
							color: #f00;
						}
					}
				}
			}
		}
	}
}
.bz_content {
	margin-bottom: 40px;
	padding-left: 30px;
	line-height: 30px;
	// height: 30px;
	color: #f00;
}

.removePadding {
	padding: 0;
}
.lookColor {
	height: 50px;
	line-height: 50px;
}
.notes {
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	display: flex;
	align-items: center;
	margin-top: 20px;
	padding-left: 15px;
	color: #626262;
	span:first-child {
		width: 30px;
		height: 20px;
		display: inline-block;
		background: rgba(38, 154, 46, 1);
		margin: 0 10px 0 15px;
	}
	span:last-child {
		width: 30px;
		height: 20px;
		display: inline-block;
		background: rgba(255, 129, 129, 1);
		margin: 0 10px 0 25px;
	}
}
</style>
